<template>
  <div class="page page--error">
    <Header :title="'Rollee Ride'" :hasClose="false" />
    <div class="page__content">
      <h1>{{ getError?.message || 'Error' }}</h1>
      <p>{{ getError?.description || 'Make sure you have correct link to use application.' }}</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header';

export default {
  name: 'Error',
  components: {
    Header,
  },
  computed: {
    ...mapGetters(['getError']),
  },
  mounted() {
    this.$store.state.connectedSources = [];
  },
  unmounted() {
    this.$store.state.selectedSource = undefined;
    this.$store.commit('SET_FETCH_UUID', null);
  },
};

</script>
<style lang="scss">
</style>
