const mixin = {
  name: 'Global',
  methods: {
    getAvatar: (data) => {
      const absolute = new RegExp('^(?:[a-z+]+:)?//', 'i');
      if (absolute.test(data?.avatar)) {
        return data.avatar;
      }
      if (data?.avatar) {
        return `${process.env.VUE_APP_API_CONNECT_PATH}${data.avatar}`;
      }
      return false;
    },
  },
};

export default mixin;
