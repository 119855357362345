<template>
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.879 4.99956L0.166504 1.28706L1.227 0.226562L6 4.99956L1.227
    9.77256L0.166504 8.71206L3.879 4.99956Z"
      fill="#A8AFBC" />
  </svg>
</template>
<script>

export default {
  name: 'CarrotRightIcon',
};
</script>
