<template>
  <div>
    <ul class="tab__wrapper">
      <li
        class="tab__item"
        :class="{ 'tab-active': index === getOnLoginTab }"
        v-for="(tab, index) in tabList"
        :key="index">
        <button
          class="tab__button"
          @click="onSelected(index)"
        > <Translate :translate="`${tab}Tab`" /> </button>
      </li>
    </ul>
    <TransitionGroup mode="out-in" tag="div" class="tab__field">
      <template v-for="(tab, index) in tabList">
        <div  :key="index" v-if="index === getOnLoginTab">
          <slot :name="tab" />
        </div>
      </template>
    </TransitionGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      selectedTab: 0,
      transitionName: 'next',
    };
  },
  props: {
    tabList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['getOnLoginTab']),
  },
  methods: {
    onSelected(value) {
      this.transitionName = this.selectedTab > value ? 'next' : 'prev';
      this.selectedTab = value;
      this.$store.commit('SET_LOGIN_TAB', value);
      this.$emit('onTabSelected', this.getOnLoginTab);
      window?.analytics.track(`${value === 0 ? 'phone' : 'email'}_login`)
    },
  }
};
</script>

<style lang="scss">
.tab {
  &__wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 48px;
    list-style: none;
    padding: 0px;
    margin-bottom: 24px;
    border-bottom: 1px solid $light-grey;
    transform: translateX(-20px);
    width: calc(100% + 40px);
  }

  &__item{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

  }

  &__button{
    color: $dark-grey;
    background-color: transparent;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    &:hover{
      background-color: transparent;
    }
  }

  &__field{
    overflow: hidden;
  }
  &-active{
    box-shadow: 0px 4px 0px -2px $main-blue;
    & .tab__button{
      color: $dark-main;

    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all .2s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
.fade-enter-active {
  transition-delay: 1.6s;
}
</style>
