<template>
  <div class="source__wrapper">

    <img
      v-if="this.source?.merged_logo"
      :src="this.source?.merged_logo"
      :alt="this.source?.merged_name"
      :class="{ 'source__wrapper-shadow': withShadow }">

    <img
      v-else
      :src="getAvatar(this.source)"
      :alt="source?.name"
      :class="{ 'source__wrapper-shadow': withShadow }">
    <img
      v-if="!getAvatar(this.source) && getSettings.isDemo"
      :src="getDefaultImage"
      :alt="source?.name"
      :class="{ 'source__wrapper-shadow': withShadow }">
    <SuccessIcon class="source__success" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SuccessIcon from '@/components/icons/SuccessIcon';

export default {
  name: 'DataSourceAvatar',
  components: {
    SuccessIcon,
  },
  props: {
    source: {
      avatar: undefined,
      name: undefined,
    },
    withShadow: undefined,
  },
  computed: {
    ...mapGetters(['getAvailableSources', 'getSettings']),
    getDefaultImage() {
      const defaultImage = this.getAvailableSources.sources?.find((item) => item.name === 'Uber')?.avatar;
      return defaultImage || 'https://static-assets-s3.dev.getrollee.com/public/logo/uber.webp';
    },
  },
};
</script>
<style scoped lang="scss">
.source{
  &__wrapper {
    position: relative;
    & img{
      height: 48px;
      width: 48px;
      border-radius: 999px;
      border: 1px solid $grey-outline;
    }

    &-shadow{
      filter: drop-shadow(0px 4px 4px rgba(20, 20, 20, 0.06));
    }
  }

  &__success{
    position: absolute;
    top: 0;
    right: -10px;
  }
}
</style>
