import { mapGetters } from 'vuex';

const mixin = {
  name: 'general',
  computed: {
    ...mapGetters(["getSettings", "getConfig"]),
    isMobile() {
      // For development purposes add in env file VUE_APP_LAYOUT=mobile
      if(process.env?.VUE_APP_LAYOUT === 'mobile') {
        return true;
      }
      if(this.getConfig?.isMobile) {
        return true;
      }
      // IF in demo mode set isMobile to true for desktop customization
      if(this.getSettings?.isDemo) {
        return true;
      }
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];
      return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
    },
  },
};

export default mixin;
