<template>
  <div class="connect__sources">
    <h2 class="connect__sources-title"><Translate :translate="'connectedAccounts'" /></h2>
    <ul>
      <li
        class="connect__sources-item"
        v-for="item in getConnectedSources.slice().reverse()"
        :key="item.name"
      >
        <DataSourceAvatar :source="item" />
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DataSourceAvatar from '@/components/DataSourceAvatar';

export default {
  name: 'ConnectedSources',
  components: {
    DataSourceAvatar,
  },
  computed: {
    ...mapGetters(['getConnectedSources']),
  },
};
</script>

<style scoped lang="scss">
  .connect {
    &__sources{
      width: calc(100% + 40px);
      box-sizing: border-box;
      padding: 8px 20px;
      display: grid;
      grid-template-rows: 20px auto;
      row-gap: 4px;
      border-top: 2px solid $grey-outline;
      box-shadow: 0px -4px 20px 0px rgba(20, 20, 20, 0.05);
      background-color: $white;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      min-height: 100px;
      z-index: 10;
      & ul{
        display: flex;
        padding: 0;
        overflow: auto;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
          display: none;  /* Safari and Chrome */
        }
      }

      &-title{
        font-size: 14px;
        line-height: 16px;
        color: $dark-grey;
        font-weight: 500;
        text-align: start;
      }

      &-item{
        position: relative;
        list-style: none;
        margin-right: 18px;
        :deep(img) {
          object-fit: contain !important;
        }
      }
    }
  }
</style>
