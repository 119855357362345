<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00002 17.3332C4.39752 17.3332 0.666687 13.6023 0.666687 8.99984C0.666687
    4.39734 4.39752 0.666504 9.00002 0.666504C13.6025 0.666504 17.3334 4.39734 17.3334
    8.99984C17.3334 13.6023 13.6025 17.3332 9.00002 17.3332ZM9.00002 15.6665C10.7681
    15.6665 12.4638 14.9641 13.7141 13.7139C14.9643 12.4636 15.6667 10.7679 15.6667
    8.99984C15.6667 7.23173 14.9643 5.53604 13.7141 4.28579C12.4638 3.03555 10.7681
    2.33317 9.00002 2.33317C7.23191 2.33317 5.53622 3.03555 4.28598 4.28579C3.03573
    5.53604 2.33335 7.23173 2.33335 8.99984C2.33335 10.7679 3.03573 12.4636 4.28598
    13.7139C5.53622 14.9641 7.23191 15.6665 9.00002 15.6665ZM9.00002 7.8215L11.3567
    5.464L12.5359 6.64317L10.1784 8.99984L12.5359 11.3565L11.3567 12.5357L9.00002
    10.1782L6.64335 12.5357L5.46419 11.3565L7.82169 8.99984L5.46419 6.64317L6.64335
    5.464L9.00002 7.8215Z"
      fill="currentColor" />
  </svg>
</template>
<script>

export default {
  name: 'ClosedOutlinedIcon',
};
</script>
