<template>
  <div class="page page--login" :class="{ rtl: isRtl }">
    <Header :hasClose="true" :hasBack="true" @goBack="goBack" />
    <div class="page__content" v-if="getSelectedSource">
      <div class="login__source" :class="{ horizontal: tabs.length > 1 }">
        <div
          class="login__source-icon"
          :class="{
            'login__source--merged': getSelectedSource?.merged_logo,
          }">
          <img
            v-if="getSelectedSource.merged_logo"
            ref="sourceLogo"
            :src="getSelectedSource.merged_logo" />
          <img
            v-if="getAvatar(getSelectedSource) && !getSelectedSource.merged_logo"
            ref="sourceLogo"
            :src="getAvatar(getSelectedSource)" />
          <div
            v-if="!getAvatar(getSelectedSource) && !getSelectedSource.merged_logo"
            class="page__list-default" />
        </div>
        <div :dir="textDirection" class="login__source-title">
          <h2>
            <Translate :translate="'logInTo'" />
            <p v-html="getSelectedSource?.merged_name || getSelectedSource.name" />
          </h2>
        </div>
      </div>
      <ErrorNotification v-if="getError" />
      <Tabs v-if="tabs.length > 1" :tabList="tabs" @onTabSelected="onResetLoginData($event)">
        <template v-slot:[tab] v-for="tab in tabs">
          <LoginFields
            class="login__form-input"
            :tabName="tab"
            :key="tab"
            @login="loginData = $event"
            @continueEnabled="continueEnabled = $event" />
        </template>
      </Tabs>
      <template v-else>
        <LoginFields
          class="login__form-input"
          :tabName="tabs[0]"
          @login="loginData = $event"
          @continueEnabled="continueEnabled = $event" />
      </template>
      <a
        v-if="getTaxInfoDatasources"
        class="page__external-link text-left"
        @click="openTaxModal()">
        <Translate :translate="'externalLinkAgenciaTributaria'" /> >
      </a>
      <div class="login__form-button">
        <button
          class="button--block"
          :style="`background: ${getConfig.cta_button_backcolor};
                  color: ${getConfig.cta_button_forecolor}`"
          @click="login"
          @keydown.enter="login"
          :disabled="!continueEnabled">
          <Translate :translate="'continue'" />
        </button>
      </div>
      <div class="login__form-button" v-if="getSelectedSource.url === 'heetch.com'">
        <button class="button--block button--facebook" @click="openFacebookModal()">
          <FacebookIcon />
          <Translate :translate="'logInWithFacebook'" />
        </button>
      </div>
      <p class="login__forgot">
        <a @click="forgotCredentials()" v-if="getSelectedSource['forgot-password-url']">
          <Translate :translate="'forgotPassword'" />
        </a>
      </p>
    </div>
    <div class="login__footer-wrapper">
      <TermsOfUseLogin />
    </div>
    <CountryList v-if="getCountryModal" />
    <Modal v-if="getLoadMoreModal" :title="translations.modalProtectDataTitle">
      <div v-html="modalContent"></div>
    </Modal>
    <Modal v-if="getTaxModal" :title="translations.modalTaxHeader">
      <div v-html="getTaxModalContent"></div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Localization from '@/mixins/localization';
import TermsOfUseLogin from '@/components/TermsOfUseLogin';
import Tabs from '@/components/Tabs';
import LoginFields from '@/components/LoginFields';
import Header from '../components/Header';
import CountryList from '../components/CountryList';
import ErrorNotification from '../components/ErrorNotification';
import FacebookIcon from '../components/icons/FacebookIcon';
import Modal from '../components/Modal';

export default {
  components: {
    Tabs,
    LoginFields,
    TermsOfUseLogin,
    Header,
    CountryList,
    ErrorNotification,
    Modal,
    FacebookIcon,
  },
  name: 'Login',
  mixins: [Localization],
  data() {
    return {
      loginData: {
        country: undefined,
        username: undefined,
        password: undefined,
        date: undefined,
      },
      continueEnabled: false,
      modalContent: null,
      getTaxModalContent: null,
      taxInfoDatasources: ['agencia-tributaria', 'impotsgouv-rev'],
    };
  },
  computed: {
    ...mapGetters([
      'getSelectedSource',
      'getAvailableSources',
      'getCountryModal',
      'getCountrySelected',
      'getError',
      'getConfig',
      'getLoginCredentials',
      'getLoadMoreModal',
      'getLocale',
      'translations',
      'getTaxModal'
    ]),
    getTaxInfoDatasources() {
      return this.taxInfoDatasources.includes(this.getSelectedSource.url);
    },
    tabs() {
      if (!this.getSelectedSource['login-fields']) {
        return ['default'];
      }
      // Map required Tab per field,
      const tabsRequired = this.getSelectedSource['login-fields'].map((source) => source?.tab);
      // Reduce Array to uniq tab values
      return [...new Set(tabsRequired)];
    },
    redirectionLink() {
      switch (this.getConfig?.lang) {
        case 'es':
          return 'https://sede.agenciatributaria.gob.es/Sede/ayuda/consultas-informaticas/firma-digital-sistema-clave-pin-tecnica/incidencias-tecnicas-clave-pin/introduzca-soporte-valido.html';
        default:
          return 'https://sede.agenciatributaria.gob.es/Sede/en_gb/ayuda/consultas-informaticas/firma-digital-sistema-clave-pin-tecnica/incidencias-tecnicas-clave-pin/introduzca-soporte-valido.html';
      }
    },
  },
  methods: {
    ...mapActions(['getDataProtectPolicy']),
    openTaxModal() {
      this.$store.dispatch('getTaxInfo', this.getSelectedSource.url).then((data) => {
        this.getTaxModalContent = data;
      });
      this.$store.commit('SET_TAX_MODAL', true);
    },
    forgotCredentials() {
      // open new window
      window.open(this.getSelectedSource['forgot-password-url'], '_blank');
      window?.analytics.track('forgot_pass_login');
    },
    goBack() {
      this.onResetLoginData();
      if(this.getAvailableSources.sources.length === 1){
        this.$router.push({ name: 'Terms', query: this.$route.query });
        return;
      }
      this.$router.push({ name: 'Search', query: this.$route.query });
    },
    openFacebookModal() {
      this.$store.commit('SET_FACEBOOK_MODAL', true);
    },
    login() {
      if (!this.continueEnabled) {
        return;
      }
      this.$store.commit('SET_AUTH', true);
      this.$store.commit('SET_LOGIN_CREDENTIALS', this.loginData);
      this.$router.push({ name: 'Fetch', query: this.$route.query });
      this.$store.commit('SET_ERROR', null);
      window?.analytics.track('continue_login');
    },
    onResetLoginData() {
      this.loginData = {
        username: undefined,
        date: undefined,
        password: undefined,
      };
      this.continueEnabled = false;
      this.$store.commit('SET_LOGIN_CREDENTIALS', this.loginData);
    },

  },
  mounted() {
    this.getDataProtectPolicy(this.getLocale).then((data) => {
      this.modalContent = data;
    });
  },
  unmounted() {
    this.$store.commit('SET_COUNTRY_SELECTED', undefined);
  },
};
</script>

<style lang="scss" scoped>
.login{
  &__footer{
    &-wrapper{
      position: absolute;
      bottom: 0;
      padding: 0 20px 20px 20px
    }
  }
}
</style>
