const dispatch = (data) => {
  if (window.rolleeWorker) {
    return window.rolleeWorker.postMessage(data);
  }
  return undefined;
};

const callback = (payload) => {
  const dataMapper = {
    accountId: payload.data.account_id,
    datasourceId: payload.data.datasource_id,
    device: payload.data.device,
    previousScreenDuration: payload.data.duration,
    fetchId: payload.data.fetch_id,
    previousScreen: payload.data.previous_screen,
    screen: payload.data.screen,
    userId: payload.data.userId,
    //! When status 'unknown' or 'server-error' map to error
    status: payload.data.status === 'unknown' || payload.data.status === 'server-error' ? 'error' : payload.data.status,
    description: payload.data.status_description,
  };
  const analyticsCallbackParameters = {
    onAnalytics: true,
    ...dataMapper,
  };

  const sAnalyticsCallbackParameters = JSON.stringify(analyticsCallbackParameters);

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(sAnalyticsCallbackParameters);
    return;
  }

  window.top.postMessage(sAnalyticsCallbackParameters, '*');
  // eslint-disable-next-line
  window.RolleeConnectFlutter && window.RolleeConnectFlutter.postMessage(sAnalyticsCallbackParameters);
};

export default {
  dispatch,
  callback,
};
