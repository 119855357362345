import { createApp } from 'vue/dist/vue.esm-bundler';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { makeServer } from '@/api/demo-api';
import router from './router';
import i18n from './i18n';
import store from './store';
import App from './App';
import _translate from './components/_translate';
import GlobalMixin from './mixins/global';

(async () => {
  const app = createApp(App);
  const urlParams = new URLSearchParams(window.location.search);
  const Qparams = Object.fromEntries(urlParams.entries());

  if (Qparams.demo) {
    store.state.settings.isDemo = true;
    const fetchOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };
    await fetch(process.env.VUE_APP_DEMO_DATASOURCES, fetchOptions).then(async (res) => {
      const data = await res.json();
      store.state.config.sources_temp = data;
    });
    await makeServer();
  }
  if (Qparams.customization_id) {
    // Check if customization id was provided in query parameters
    // add to store to be used for the partner API call
    store.state.config.customization_id = Qparams.customization_id;
    sessionStorage.setItem('customization_id', Qparams.customization_id);
  }

  await store.dispatch('getConfiguration').then((res) => {
    if (res?.response?.status === 500) {
      // redirect to error page
      // add error to store

      router.push({ name: 'Error' });
    }
  });

  await store.dispatch('getSessionInfo');

  if (Qparams.isMobile === 'true') {
    store.state.config.isMobile = true;
  }

  if (Qparams.remove_close_button === 'true') {
    store.state.config.remove_close_button = true;
  }
  if (Qparams.skip_datasources_page === 'true') {
    store.state.config.skip_datasources_page = true;
  }
  if (Qparams.skip_first_page === 'true') {
    store.state.config.skip_first_page = true;
  }
  if (Qparams.datasource) {
    store.state.config.datasource = Qparams.datasource;
  }
  if (Qparams.url) {
    store.state.config.redirect = Qparams.url;
  }

  window?.analytics.page('Connect started', {
    config: {
      partnerName: store.state.config.partner_name,
      partnerTitle: store.state.config.partner_title,
      personId: store.state.config.person_id,
      phoneCode: store.state.config.phone_code,
      uuid: store.state.config.uuid,
    }
  });

  const translations = (await i18n.common(store.state.config.lang)).default;
  store.state.translations = translations;

  app.use(store);
  app.use(router);
  app.use(VueAxios, axios);
  app.component('Translate', _translate);
  app.mixin(GlobalMixin);
  app.mount('#app');
})();
