<template>
  <div id="spinnerAnimation" class="spinner__wrapper" />
</template>
<script>

export default {
  name: 'LoadingSpinnerIcon',
  data() {
    return {
      bodymovinInstance: undefined,
    };
  },
  mounted() {
    this.setupMovinAnimation();
  },
  methods: {
    async setupMovinAnimation() {
      // eslint-disable-next-line
      const bodymovinInstance = await bodymovin.loadAnimation({
        container: document.getElementById('spinnerAnimation'),
        renderer: 'svg',
        // eslint-disable-next-line import/extensions,global-require,import/no-unresolved
        animationData: require('@/../public/animations/connect-spinner.json'),
        name: 'spinnerAnimation',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
          progressiveLoad: true,
          runExpressions: true,
        },
      });
      bodymovinInstance.setSpeed(0.5);
      this.bodymovinInstance = bodymovinInstance;
    },
  },
};
</script>
<style scoped lang="scss">
.spinner{
  &__wrapper{
    height: 17px;
    width: 17px;
    background: transparent;
  }
}
</style>
