<template>
  <div class="page page--login" :class="{ rtl: isRtl }">
    <Header :hasClose="false" :hasBack="true" @goBack="goBack" />
    <div class="login__source">
      <div class="login__source-icon">
        <img
          v-if="getAvatar(getSelectedSource)"
          ref="sourceLogo"
          :src="getAvatar(getSelectedSource)" />
        <div
          v-if="!getAvatar(getSelectedSource)"
          class="page__list-default" />
      </div>
      <div :dir="textDirection" class="login__source-title">
        <h2>
          <Translate :translate="'logInTo'" />
          <p v-html="getSelectedSource?.name" />
        </h2>
      </div>
    </div>
    <div class="login__form">

      <ErrorNotification v-if="getError" />
      <FileUpload @file="onFile" />
      <div class="login__form-note">
        <ExclamationOutlinedIcon />
        <span>The file must be 6 months statement from the application</span>
      </div>
      <!-- Add password field once BA is ready -->
      <div class="login__form-button">
        <button
          class="button--block"
          :style="`background: ${getConfig.cta_button_backcolor};
                      color: ${getConfig.cta_button_forecolor}`"
          @click="onUpload"
          @keydown.enter="onUpload"
          :disabled="!continueEnabled">
          <Translate :translate="'continue'" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Localization from '@/mixins/localization';
import FileUpload from '@/components/inputs/FileUpload';
import ExclamationOutlinedIcon from '@/components/icons/ExclamationOutlinedIcon';
import ErrorNotification from '@/components/ErrorNotification';
import Header from '../components/Header';

export default {
  components: {
    Header,
    FileUpload,
    ExclamationOutlinedIcon,
    ErrorNotification,
  },
  name: 'Login',
  mixins: [Localization],
  data() {
    return {
      uploadData: {
        file: undefined,
        password: undefined,
      },
      passwordShow: false,
    };
  },
  computed: {
    ...mapGetters([
      'getSelectedSource',
      'getCountryModal',
      'getCountrySelected',
      'getError',
      'getConfig',
      'getLoginCredentials',
    ]),
    continueEnabled() {
      return this.uploadData.file;
    },
  },
  beforeMount() {
    if (this.getSelectedSource === undefined) {
      this.$router.push({ name: 'Terms', query: this.$route.query });
    }
  },
  mounted() {
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'Search', query: this.$route.query });
    },
    onUpload() {
      const formData = new FormData();
      formData.append('file', this.uploadData.file);
      formData.append('datasource', this.getSelectedSource.ID);
      this.$store.commit('SET_DOCUMENT_UPLOAD', formData);
      this.$router.push({ name: 'Upload', query: this.$route.query });
    },
    onFile(file) {
      this.$store.commit('SET_ERROR', null);
      this.uploadData.file = file;
    },
  },
};
</script>
<style scoped lang="scss">

.login{
  &__form {
    width: 100%;
    &-password {
      font-family: 'text-security-disc' !important;
      font-size: 17px !important;
      -webkit-text-security: disc !important;
      &::placeholder {
        font-size: 17px;
        font-family: 'Gilroy', sans-serif;
      }
      &.show-password {
        font-size: 17px !important;
        font-family: 'Gilroy', sans-serif !important;
        -webkit-text-security: none !important;
      }
    }

    &-note{
      display: grid;
      column-gap: 6px;
      align-items: center;
      grid-template-columns: 20px 1fr;
      color: $dark-grey;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 17.5px;

      & span{
        transform: translateY(1px);
      }
    }
    &-button{
      margin-top: 16px;
    }
  }
}
.login{
  &__source{
    width: 100%;
  }
}
</style>
