<template>
  <svg width="17" height="17" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0259 12.8475L17.5951 16.4158L16.4159 17.595L12.8476 14.0258C11.5199
    15.0902 9.86842 15.6691 8.16675 15.6667C4.02675 15.6667 0.666748 12.3067
    0.666748 8.16667C0.666748 4.02667 4.02675 0.666672 8.16675 0.666672C12.3067 0.666672
    15.6667 4.02667 15.6667 8.16667C15.6692 9.86834 15.0903 11.5198 14.0259 12.8475ZM12.3542
    12.2292C13.4118 11.1416 14.0025 9.6837 14.0001 8.16667C14.0001 4.94334 11.3892 2.33334
    8.16675 2.33334C4.94341 2.33334 2.33341 4.94334 2.33341 8.16667C2.33341 11.3892 4.94341 14
    8.16675 14C9.68377 14.0024 11.1417 13.4118 12.2292 12.3542L12.3542 12.2292Z"
      fill="currentColor"
    />
  </svg>
</template>;
