<template>
  <div class="page modal-approval" :class="{ 'page--remove': removeModal }">
    <Header :hasClose="true" />
    <div class="page__content">
      <div>
        <div class="modal__logo">
          <img
            v-if="getAvatar(getSelectedSource)"
            :src="getAvatar(getSelectedSource)"
            alt="Logo" />
          <div class="modal__animation__wrapper">
            <div class="modal__animation" id="approvalAnimation" />
          </div>
          <p class="modal__title">
            <Translate :translate="'approvalSignIn'" />
            <span class="modal__title-highlight">
              {{ dataSourceName }}
            </span>
          </p>
        </div>
        <div class="modal__code">
          <ErrorNotification v-if="getError" />
          <div class="login__source-info">
            <p>
              {{translations.otpCode}}
            </p>
            <span v-if="displayLoginWith">
              {{ displayLoginWith }}
            </span>
            <span v-else>
              <Translate :translate="'otpDefault'" />
            </span>
          </div>
          <div class="input__wrapper">
            <textarea
              v-model="code"
              data-gramm="false"
              data-hj-allow
              class="data-hj-allow"
              @keydown.enter.prevent
              autofocus
              rows="0" />
          </div>
          <div class="page__actions">
            <button
              @click="onSendOtp"
              :disabled="isLoading || isDisabled"
              class="button button--primary button--block"
              :class="{ 'button--loading': getFetchUUID }"
            >
              <Translate :translate="'send'" />
            </button>
            <button
              v-if="getSelectedSource['resend-otp']"
              :disabled="resendDisabled"
              @click="onResend"
              class="button button--secondary button--block"
              :class="{ 'button--loading': getFetchUUID }"
            >
              <Translate :translate="'resendCode'" />
            </button>
            <div v-if="resendDisabled" class="page__actions-info">
              <span><Translate :translate="'resendWait'" /></span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-separation">
        <span>
          or
        </span>
      </div>
      <div class="modal__auth">
        <PhoneIcon />
        <p>
          Verify the authentication sent
          to your device to continue
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header';
import Analytics from '@/api/analytics';
import ErrorNotification from '@/components/ErrorNotification';
import PhoneIcon from '@/components/icons/PhoneIcon';

export default {
  name: 'ApprovalCodeModal',
  components: {
    PhoneIcon,
    ErrorNotification,
    Header,
  },
  data() {
    return {
      code: undefined,
      resendDisabled: false,
      disableButton: true,
      removeModal: false,
      isLoading: false,
      bodymovinInstance: undefined,
    };
  },
  mounted() {
    this.setupMovinAnimation();
    setTimeout(() => {
      this.disableButton = false;
    }, 3000);

    Analytics.dispatch({
      action: 'getSessionDuration',
      fromScreen: this.$route.name,
      toScreen: 'ApprovalOTP',
    });
  },
  computed: {
    ...mapGetters([
      'getSelectedSource',
      'getFetchStatus',
      'translations',
      'getError',
      'getFetchUUID',
      'getLoginCredentials',
    ]),
    displayLoginWith() {
      switch (this.getLoginCredentials?.type) {
        case 'phone':
          return this.getDialCode();
        case 'email':
          if (this.getFetchStatus['otp-status'] === 'MAIL_OTP') {
            return this.getLoginCredentials?.username;
          }
          return ' ';
        default:
          return ' ';
      }
    },
    isDisabled() {
      // eslint-disable-next-line
      return this.code || this.code?.length ? false : true;
    },
    dataSourceName() {
      if (this.getSelectedSource.child_datasources) {
        if (this.getSelectedSource.next_datasource_id) {
          return this.getSelectedSource.child_datasources[0].title;
        }
        return this.getSelectedSource.child_datasources[1].title;
      }
      return this.getSelectedSource.name;
    },
  },
  methods: {
    onSendOtp() {
      this.removeModal = true;
      this.isLoading = true;
      this.$store.dispatch('sendConfirmationCode', this.code).then(() => {
        setTimeout(() => {
          this.closeModal();
        }, 500);
      }).catch(() => {
        this.isLoading = true;
        this.$store.commit('SET_ERROR', {
          name: 'Error',
          description: 'Error while sending confirmation code.',
        });
      });
    },
    onResend() {
      // Disable Resend input for 15sec and display 'resendWait' message when disabled
      this.resendDisabled = true;
      setTimeout(() => {
        this.resendDisabled = false;
      }, 15000);
      // Check property if resend is allowed for that datasource
      if (this.getSelectedSource['resend-otp']) {
        this.$emit('resend');
      }
    },
    async setupMovinAnimation() {
      // eslint-disable-next-line
      const bodymovinInstance = await bodymovin.loadAnimation({
        container: document.getElementById('approvalAnimation'),
        renderer: 'svg',
        // eslint-disable-next-line import/extensions,global-require,import/no-unresolved
        animationData: require('@/../public/animations/approval-otp-spinner.json'),
        name: 'fetchAnimation',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
          progressiveLoad: true,
          runExpressions: true,
        },
      });
      this.bodymovinInstance = bodymovinInstance;
    },
    getDialCode() {
      const [code, phone] = this.getLoginCredentials.username.split('-');
      const dialCode = this.getCountries.find((country) => country.code === code);
      return `${dialCode.dial_code} ${phone}`;
    },
    closeModal() {
      this.isLoading = false;
      this.$store.commit('SET_ERROR', null);
      this.$store.commit('SET_APPROVAL_WITH_CODE_MODAL', false);
    },
  },
};
</script>

<style scoped lang="scss">
.page{
  &__actions{
    margin-top: 28px;
  }

  &__content{
    display: flex;
    flex-direction: column;
  }
}
.modal{
  &-separation{
    border-bottom: 1px solid $grey-outline;
    margin: 54px 0;
    span{
      color: $dark-grey;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 0 20px;
      background: #fff;
      transform: translateY(10px);
      display: inline-block;
    }
  }
  &__code{
    margin-top: 22px;
  }
  &__animation{
    &__wrapper{
      display: inline-block;
      transform: translateX(-8px) translateY(-16px);
    }
  }
  &__logo{
    display: flex;
    grid-template-columns: 52px auto;
    column-gap: 24px;
    align-items: center;
    padding-left: 8px;
    margin-bottom: 24px;
  }
  &__auth{
    border-radius: 20px 20px 0px 0px;
    padding: 24px 0;
    background: $white;
    box-shadow: 0px -4px 30px 0px rgba(20, 20, 20, 0.05);
    margin-top: auto;
    height: 20%;
    width: 100%;
    transform: translateX(-20px);
    display: grid;
    grid-template-rows: 32px auto;
    row-gap: 16px;
    justify-items: center;
    position: absolute;
    bottom: 0;
    p {
      padding: 0 55px;
      color: $black;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.5px
    }
  }
}
.code{
  &__source {
    margin: 24px 0 20px 0;
  }
  &__tips {
    color: $dark-grey;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.5px;
    text-align: start;
    margin-bottom: 12px;
    transform: translateY(-8px);
  }
}
.page {
  &__actions {
    button:first-of-type {
      margin-bottom: 20px;
    }
    &-info {
      margin-top: 6px;
      span {
        color: $dark-grey;
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 17.5px
      }
    }
  }
}
.input__wrapper {
  margin-top: 22px;
  min-height: 32px;
  textarea {
    letter-spacing: 4px;
  }
}
</style>
