<template>
  <footer class="footer">
    <slot />
    <div v-if="!hasContent" class="text--center" :dir="textDirection">
      <Translate class="footer__text" :translate="'footerTitle'" />
      <span class="footer__text footer__company">rollee</span>
      <LogoIcon class="footer__logo" />
    </div>
  </footer>
</template>

<script>
import Localization from '@/mixins/localization';
import LogoIcon from './icons/LogoIcon';

export default {
  components: { LogoIcon },
  mixins: [Localization],
  computed: {
    hasContent() {
      return this.$slots.default;
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  background: #fff;
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -12px;
    left: 0;
    width: 100%;
    height: 12px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%);
  }
  &__text {
    font-size: 16px;
    span {
      font-size: inherit;
    }
  }
  &__logo {
    transform: translateY(5px);
  }
  &__company {
    padding: 0 4px;
    color: $text-color-brand;
  }
}
</style>
