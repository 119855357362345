<template>
  <div class="error">
    <ExclamationIcon class="error__icon" />
    <span class="error__desc">{{ errorDescription }}</span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ExclamationIcon from './icons/ExclamationIcon';

export default {
  name: 'ErrorNotification',
  computed: {
    ...mapGetters(['getError', 'getFetchStatus', 'translations']),
    errorDescription() {
      // Check if description provided in error
      if (this.getError.description) {
        return this.getError.description;
      }
      // Display default error message when non are provided
      return this.translations.errors.generic;
    },
  },
  components: { ExclamationIcon },
};
</script>
<style lang="scss" scoped>
.error {
  display: grid;
  grid-template-columns: 18px auto;
  column-gap: 8px;
  align-items: center;
  justify-content: flex-start;
  background: #F5292214;
  width: 100%;
  min-height: 40px;
  border-radius: 8px;
  padding: 8px 12px;
  box-sizing: border-box;
  margin-bottom: 20px;
  &__desc {
    color: #F52922;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: start;
  }
  &__icon {
    justify-self: center;
  }
}
</style>
