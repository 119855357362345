export default {
  common: (locale) => {
    switch (locale) {
      case 'fr':
        return import(/* webpackChunkName: "i18n.common.fr" */'./common.fr');
      case 'ar':
        return import(/* webpackChunkName: "i18n.common.ar" */'./common.ar');
      case 'es':
        return import(/* webpackChunkName: "i18n.common.ar" */'./common.es');
      case 'ro':
        return import(/* webpackChunkName: "i18n.common.ar" */'./common.ro');
      case 'pl':
        return import(/* webpackChunkName: "i18n.common.pl" */'./common.pl');
      default:
        return import(/* webpackChunkName: "i18n.common" */'./common');
    }
  },
};
