<template>
  <label class="switch">
    <input type="checkbox" class="switch__checkbox" v-model="isActive" :value="value">
    <span class="switch__toggle" />
  </label>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isActive: this.value,
    };
  },
  watch: {
    isActive(val) {
      this.$emit('checked', val);
    },
  },
};
</script>
<style lang="scss" scoped>
$switch-size: 16px;
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
  cursor: pointer;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .switch__toggle {
      background-color: $checkbox-background-color;
      border-color: $checkbox-background-color;
      &:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }
    }
    &:focus + .switch__toggle {
      box-shadow: 0 0 1px $checkbox-background-color;
    }
  }
  &__toggle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: #e2e2e2;
    -webkit-transition: $transition;
    transition: $transition;
    &:before {
      position: absolute;
      content: "";
      height: $switch-size;
      width: $switch-size;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: $transition;
      transition: $transition;
      border-radius: 50%;
    }
  }
}
</style>
