<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="22.5"
    height="27.5"
    viewBox="0 0 22.5 27.5"
    fill="none"
    version="1.1"
    id="svg18"
    sodipodi:docname="Protected.svg"
    inkscape:version="1.0.2-2 (e86c870879, 2021-01-15)"
  >
    <metadata
      id="metadata22"
    >
      <rdf:RDF>
        <cc:Work
          rdf:about=""
        >
          <dc:format>image/svg+xml</dc:format>
          <dc:type
            rdf:resource="http://purl.org/dc/dcmitype/StillImage"
          />
          <dc:title />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <sodipodi:namedview
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1"
      objecttolerance="10"
      gridtolerance="10"
      guidetolerance="10"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:window-width="1920"
      inkscape:window-height="1017"
      id="namedview20"
      showgrid="false"
      fit-margin-top="0"
      fit-margin-left="0"
      fit-margin-right="0"
      fit-margin-bottom="0"
      inkscape:zoom="28.333333"
      inkscape:cx="11.25"
      inkscape:cy="13.75"
      inkscape:window-x="-8"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
      inkscape:current-layer="svg18"
    />
    <path
      d="M 0.97875,2.2825 11.25,0 21.5212,2.2825 c 0.2776,0.06171 0.5259,0.21621 0.7038,0.43801
      0.178,0.2218 0.275,0.49764 0.275,0.78199 v 12.4838 c -10e-5,1.2346 -0.305,2.4502
      -0.8876,3.5387 -0.5827,1.0886 -1.4251,2.0164 -2.4524,2.7013 L 11.25,27.5 3.34,22.2263
      C 2.31284,21.5415 1.47058,20.6139 0.88794,19.5256 0.30529,18.4373 3e-4,17.222 0,15.9875
      V 3.5025 C 5e-5,3.21815 0.09705,2.94231 0.27499,2.72051 0.45293,2.49871 0.70117,2.34421
      0.97875,2.2825 Z"
      fill="url(#paint0_linear_3465_11161)"
      id="path2"
      style="fill:url(#paint0_linear_3465_11161)"
    />
    <path
      d="M 4.4025,5.93125 11.25,4.375 18.0975,5.93125 c 0.1851,0.04207 0.3505,0.14741
      0.4692,0.29864 0.1186,0.15123 0.1833,0.3393 0.1833,0.53318 v 8.51163 c -10e-5,0.8418
      -0.2033,1.6706 -0.5918,2.4128 -0.3884,0.7422 -0.95,1.3748 -1.6349,1.8418 L 11.25,23.125
      5.97667,19.5293 C 5.29189,19.0624 4.73038,18.4299 4.34196,17.6879 3.95353,16.9459
      3.7502,16.1172 3.75,15.2756 V 6.76307 C 3.75003,6.56919 3.8147,6.38112 3.93332,6.22989
      4.05195,6.07866 4.21745,5.97332 4.4025,5.93125 Z"
      fill="url(#paint1_linear_3465_11161)"
      id="path4"
      style="fill:url(#paint1_linear_3465_11161)"
    />
    <defs
      id="defs16"
    >
      <linearGradient
        id="paint0_linear_3465_11161"
        x1="26.25"
        y1="28.75"
        x2="-0.70544702"
        y2="6.69555"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-3.75,-1.25)"
      >
        <stop
          stop-color="#919191"
          id="stop6"
        />
        <stop
          offset="1"
          stop-color="#BEBEBE"
          id="stop8"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3465_11161"
        x1="8.3333302"
        y1="8.75"
        x2="29.624701"
        y2="23.1567"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-3.75,-1.25)"
      >
        <stop
          stop-color="white"
          id="stop11"
        />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0.12"
          id="stop13"
        />
      </linearGradient>
    </defs>
  </svg>

</template>;
