<template>
  <div class="page page--countries">
    <Header
      :hasClose="false"
      :hasBack="true"
      :hasTitle="true"
      :title="'countriesTitle'"
      :hasLogo="false"
      @goBack="goBack()"
    />
    <div class="page__content">
      <div class="input__wrapper">
        <input
          type="text"
          class="input__search"
          v-model="search"
          @keydown.enter.prevent="searchCountries"
          :placeholder="searchCountriesPlaceholder"
          autofocus
        />
      </div>
      <div class="countries__wrapper">
        <ul>
          <li
            v-for="country in filterCountries(search)"
            :key="country.code"
            @click="chooseCountry(country)">
            <div class="country">
              <p class="country__flag">{{ country.flag }}</p>
              <p class="country__name">{{ country.name }}</p>
              <p v-if="!getFilterCountryModal" class="country__dial">{{ country.dial_code }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header';

export default {
  components: {
    Header,
  },
  name: 'CountryList',
  data() {
    return {
      search: '',
      searchCountriesPlaceholder: this.$store.state.translations.searchCountriesPlaceholder,
    };
  },
  computed: {
    ...mapGetters(['getFilterCountryModal', 'getSelectedSource']),
    countries() {
      if (this.getFilterCountryModal && this.getSelectedSource.available_countries?.length > 0) {
        return this.$store.state.dialCodes.filter(
          (country) => this.getSelectedSource.available_countries.includes(
            country.code.toLowerCase(),
          ),
        );
      }
      return this.$store.state.dialCodes;
    },
  },
  methods: {
    filterCountries(search) {
      return this.countries.filter((country) => country.name
        .toLowerCase().indexOf(search.toLowerCase()) > -1
        || country.dial_code.toLowerCase().indexOf(search.toLowerCase()) > -1);
    },
    goBack() {
      this.$store.commit('SET_COUNTRY_MODAL', false);
      this.$store.commit('SET_FILTER_COUNTRY_MODAL', false);
    },
    chooseCountry(country) {
      this.$store.commit('SET_COUNTRY_SELECTED', country);
      this.$store.commit('SET_COUNTRY_MODAL', false);
      this.$store.commit('SET_FILTER_COUNTRY_MODAL', false);
    },
  },
};
</script>
<style scoped lang="scss">
.page {
  &--countries {
    display: grid;
    grid-template-rows: $header-height auto !important;
    align-content: baseline !important;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top:0;
    background: $app-background-color;
    .page {
      &__content {
        display: grid;
        align-items: baseline;
        grid-template-rows: 80px auto;
        max-height: 100%;
        overflow: hidden;
        margin-top: 43px;
      }
      &__title {
        font-size: 16px;
      }
    }
  }
}
.countries {
  &__list {
    padding: 6px;
  }
  &__wrapper {
    height: 100%;
    overflow-y: auto;
    padding-right: 12px;
    &::-webkit-scrollbar {
      width: 3px;
      &-track {
        background: #fafafa
      };
      &-thumb {
        background: #ccc;
        border-radius: 3px;
        border: 1px solid #ccc;
      }
    }
    .country {
      display: grid;
      grid-template-columns: 44px auto 60px;
      height: inherit;
      align-items: center;
      &__flag {
        font-size: 24px;
        line-height: 44px;
        padding-left: 4px;
      }
      &__name {
        font-size: 14px;
        line-height: 16px;
        color: #253858;
      }
      &__dial {
        font-size: 14px;
        line-height: 44px;
        text-align: right;
        padding-right: 4px;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        text-align: left;
        border-bottom: 1px solid #F3F3F3;
        cursor: pointer;
        transition: $transition;
        &:hover {
          background: #f8f8f8;
        }
      }
    }
  }
}
</style>
