<template>
  <div class="back">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.828 6.99998H16V8.99998H3.828L9.192 14.364L7.778 15.778L0 7.99998L7.778
    0.221985L9.192 1.63598L3.828 6.99998Z"
        fill="#A8AFBC" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'BackIcon',
};
</script>

<style lang="scss" scoped>
.back {
  cursor: pointer;
  transition: $transition;
  &:hover {
    transform: translateX(-2px)
  }
  svg {
    width: 18px;
    height: 15px;
    margin-top: -1px;
  }
  p {
    display: inline;
    vertical-align: top;
    line-height: 16px;
    margin-left: 6px;
    transform: translate(5px, -3px)
  }
}
</style>
