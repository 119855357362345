<template>
  <div class="file__wrapper" v-if="!fileName">
    <!-- <p class="file__title">
      {{ label }}
    </p> -->
    <div class="file">
      <div class="file__image">
        <AddIcon />
      </div>
      <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
      <input
        type="file"
        @change="onChange($event)"
        ref="input"
        :title="fileTitle">
      <div class="file__text">
        <p class="file__text-main">
          {{text}}
        </p>
        <p class="file__text-info">
          {{info}}
        </p>
      </div>
    </div>
  </div>
  <div class="file__preview" v-else>
    <div class="file__preview-image">
      <img src="../../assets/file-icon.png" alt="file" ref="preview">
      <SuccessIcon class="file__preview-success" />
    </div>
    <span class="file__preview-name">{{ fileName }}</span>
    <DeleteIcon @keyup="clearFile()" @click="clearFile()" class="ri-delete-bin-7-line" />
  </div>
</template>
<script>
import AddIcon from '@/components/icons/AddIcon';
import DeleteIcon from '@/components/icons/DeleteIcon';
import SuccessIcon from '@/components/icons/SuccessIcon';

export default {
  name: 'FileUpload',
  components: { AddIcon, DeleteIcon, SuccessIcon },
  emits: ['file'],
  props: {
    tooltipText: String,
    text: {
      type: String,
      default: 'Upload 6 month statement',
    },
    info: {
      type: String,
      default: 'Tap to upload your file',
    },
    data: {
      type: String,
      default: '',
    },
    upload: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Branding Logo',
    },
  },
  data() {
    return {
      fileName: null,
    };
  },
  computed: {
    fileTitle() {
      return this.fileName ? this.fileName : 'No file chosen';
    },
  },
  methods: {
    onChange(e) {
      const file = e.target.files[0];
      this.fileName = null;
      const reader = new FileReader();
      reader.onload = () => {
        this.filesrc = reader.result;
        this.fileName = file.name;
        this.$emit('file', file);
      };
      const formData = new FormData();
      formData.append('file', file);
      reader.readAsDataURL(file);
    },
    onClick() {
      this.$refs.input.click();
    },
    clearFile() {
      // Clear the file input
      this.$refs.input = '';
      this.fileName = null;
      this.$emit('file', null);
    },
  },
  unmounted() {
    this.clearFile();
  },
};
</script>
<style lang="scss" scoped>
.file {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 104px;
  border-radius: 8px;
  position: relative;
  &__image{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border-radius: 999px;
    height: 28px;
    width: 28px;
    border: 1px solid $grey-outline;
  }
  &__wrapper{
    margin-bottom: 12px;
    position: relative;
    background: $blue-background;
    border-radius: 8px;
    border: 1.2px solid $grey-outline;
  }
  &:hover {
    border-color: var(--color-blue-main);
  }
  &__text{
    text-align: center;
    margin-top: 12px;
    &-main{
      color: var(--black, #141414);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
    &-info{
      margin-top: 8px;
      color: $dark-grey;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }
  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  &__preview {
    display: grid;
    grid-template-columns: 44px auto 20px;
    column-gap: 20px;
    align-items: center;
    height: 106px;
    width: 100%;
    padding: 0 20px;
    border-bottom: 2px solid $grey-outline;
    background: $white;
    transform: translateX(-20px);
    margin-bottom: 12px;
    &-image{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: $blue-background;
      border-radius: 999px;
      height: 44px;
      width: 44px;
      border: 1px solid $grey-outline;
      & img{
        height: 26px;
        width: 22px;
      }
    }
    &-success{
      position: absolute;
      right: -7px;
      top: -6px;
    }
    &-name{
      color: $dark-grey;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      word-break: break-all;
    }
  }
}
</style>
