import Hotjar from '@hotjar/browser';

const setupHotjar = {
  envId: '2811744',
  hotjarVersion: '1',
};

const init = () => {
  Hotjar.init(setupHotjar.envId, setupHotjar.hotjarVersion);
};

export default {
  init,
};
