<template>
  <svg width="18" height="15" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.984131 8C1.76746 3.73333 5.50663 0.5 9.99996 0.5C14.4933 0.5 18.2316 3.73333
    19.0158 8C18.2325 12.2667 14.4933 15.5 9.99996 15.5C5.50663 15.5 1.7683 12.2667 0.984131
    8ZM9.99996 12.1667C11.105 12.1667 12.1648 11.7277 12.9462 10.9463C13.7276 10.1649 14.1666
    9.10507 14.1666 8C14.1666 6.89493 13.7276 5.83512 12.9462 5.05372C12.1648 4.27232 11.105
    3.83333 9.99996 3.83333C8.8949 3.83333 7.83509 4.27232 7.05369 5.05372C6.27228 5.83512
    5.8333 6.89493 5.8333 8C5.8333 9.10507 6.27228 10.1649 7.05369 10.9463C7.83509 11.7277
    8.8949 12.1667 9.99996 12.1667ZM9.99996 10.5C9.33692 10.5 8.70104 10.2366 8.2322
    9.76777C7.76336 9.29893 7.49996 8.66304 7.49996 8C7.49996 7.33696 7.76336 6.70107
    8.2322 6.23223C8.70104 5.76339 9.33692 5.5 9.99996 5.5C10.663 5.5 11.2989 5.76339
    11.7677 6.23223C12.2366 6.70107 12.5 7.33696 12.5 8C12.5 8.66304 12.2366 9.29893
    11.7677 9.76777C11.2989 10.2366 10.663 10.5 9.99996 10.5Z"
      fill="#A8AFBC"
    />
  </svg>
</template>;
