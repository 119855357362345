<template>
  <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4642_9502)">
      <path
        d="M10.5 18.3333C5.89752 18.3333 2.16669 14.6025 2.16669 10C2.16669 5.39751 5.89752
      1.66667 10.5 1.66667C15.1025 1.66667 18.8334 5.39751 18.8334 10C18.8334 14.6025 15.1025
      18.3333 10.5 18.3333ZM10.5 16.6667C12.2681 16.6667 13.9638 15.9643 15.2141 14.7141C16.4643
      13.4638 17.1667 11.7681 17.1667 10C17.1667 8.2319 16.4643 6.5362 15.2141 5.28596C13.9638
      4.03572 12.2681 3.33334 10.5 3.33334C8.73191 3.33334 7.03622 4.03572 5.78598 5.28596C4.53573
      6.5362 3.83335 8.2319 3.83335 10C3.83335 11.7681 4.53573 13.4638 5.78598 14.7141C7.03622
      15.9643 8.73191 16.6667 10.5 16.6667ZM9.66669 12.5H11.3334V14.1667H9.66669V12.5ZM9.66669
      5.83334H11.3334V10.8333H9.66669V5.83334Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4642_9502">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>;
