<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00001 17.3337C4.39751 17.3337 0.666672 13.6028 0.666672
    9.00033C0.666672 4.39783 4.39751 0.666992 9.00001 0.666992C13.6025 0.666992
    17.3333 4.39783 17.3333 9.00033C17.3333 13.6028 13.6025 17.3337 9.00001 17.3337ZM9.00001
    7.82199L6.64334 5.46449L5.46417 6.64366L7.82167 9.00033L5.46417 11.357L6.64334 12.5362L9.00001
    10.1787L11.3567 12.5362L12.5358 11.357L10.1783 9.00033L12.5358 6.64366L11.3567 5.46449L9.00001
    7.82199Z"
      fill="currentColor" />
  </svg>
</template>
<script>

export default {
  name: 'CloseFilledIcon',
};
</script>
<style scoped lang="scss">

</style>
