export default {
  SET_SOURCES: 'SET_SOURCES',
  SET_CONFIG: 'SET_CONFIG',
  SET_SOURCES_TEMP: 'SET_SOURCES_TEMP',
  SET_SELECTED_SOURCE: 'SET_SELECTED_SOURCE',
  SET_ERROR: 'SET_ERROR',
  SET_CONNECT_LOGO_POSITION: 'SET_CONNECT_LOGO_POSITION',
  SET_CONNECTED_SOURCES: 'SET_CONNECTED_SOURCES',
  SET_SUGGESTED_SOURCES: 'SET_SUGGESTED_SOURCES',
  SET_SEARCH_INPUT_FOCUSED: 'SET_SEARCH_INPUT_FOCUSED',
  SET_AUTH: 'SET_AUTH',
  SET_CLOSE_MODAL_ENABLED: 'SET_CLOSE_MODAL_ENABLED',
  SET_LOGIN_CREDENTIALS: 'SET_LOGIN_CREDENTIALS',
  SET_APP_UUID: 'SET_APP_UUID',
  SET_ACCOUNT_ID: 'SET_ACCOUNT_ID',
  SET_FETCH_UUID: 'SET_FETCH_UUID',
  SET_FETCH_STATUS: 'SET_FETCH_STATUS',
  SET_COUNTRY_MODAL: 'SET_COUNTRY_MODAL',
  SET_COUNTRY_SELECTED: 'SET_COUNTRY_SELECTED',
  SET_FILTER_COUNTRY_MODAL: 'SET_FILTER_COUNTRY_MODAL',
  SET_CODE_MODAL: 'SET_CODE_MODAL',
  SET_APPROVAL_MODAL: 'SET_APPROVAL_MODAL',
  SET_APPROVAL_WITH_CODE_MODAL: 'SET_APPROVAL_WITH_CODE_MODAL',
  SET_REFRESHED_UUID: 'SET_REFRESHED_UUID',
  SET_FACEBOOK_MODAL: 'SET_FACEBOOK_MODAL',
  SET_LEGAL_INFO_MODAL: 'SET_LEGAL_INFO_MODAL',
  SET_TYPE_OF_TERMS: 'SET_TYPE_OF_TERMS',
  SET_LOGIN_TAB: 'SET_LOGIN_TAB',
  SET_NEXT_DATASOURCE: 'SET_NEXT_DATASOURCE',
  SET_DOCUMENT_UPLOAD: 'SET_DOCUMENT_UPLOAD',
  SET_LOAD_MORE_MODAL: 'SET_LOAD_MORE_MODAL',
  SET_TAX_MODAL: 'SET_TAX_MODAL',
  SET_DATE_MODAL: 'SET_DATE_MODAL',
};
