<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    version="1.1"
    id="svg23"
    sodipodi:docname="KeyHole.svg"
    inkscape:version="1.0.2-2 (e86c870879, 2021-01-15)"
  >
    <metadata
      id="metadata27"
    >
      <rdf:RDF>
        <cc:Work
          rdf:about=""
        >
          <dc:format>image/svg+xml</dc:format>
          <dc:type
            rdf:resource="http://purl.org/dc/dcmitype/StillImage"
          />
          <dc:title />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <sodipodi:namedview
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1"
      objecttolerance="10"
      gridtolerance="10"
      guidetolerance="10"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:window-width="1920"
      inkscape:window-height="1017"
      id="namedview25"
      showgrid="false"
      fit-margin-top="0"
      fit-margin-left="0"
      fit-margin-right="0"
      fit-margin-bottom="0"
      inkscape:zoom="28.333333"
      inkscape:cx="13"
      inkscape:cy="13"
      inkscape:window-x="-8"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
      inkscape:current-layer="svg23"
    />
    <circle
      cx="13"
      cy="13"
      r="13"
      fill="url(#paint0_linear_3465_11147)"
      id="circle2"
      style="fill:url(#paint0_linear_3465_11147)"
    />
    <g
      clip-path="url(#clip0_3465_11147)"
      id="g6"
      transform="translate(-2,-2)"
    >
      <path
        d="m 14.9999,24.1667 c -5.06273,0 -9.16665,-4.104 -9.16665,-9.1667 0,-5.06276
        4.10392,-9.16667 9.16665,-9.16667 5.0628,0 9.1667,4.10391 9.1667,9.16667 0,5.0627
        -4.1039,9.1667 -9.1667,9.1667 z M 14.0833,15.726 v 2.3298 c 0,0.5063 0.4104,0.9167
        0.9166,0.9167 v 0 c 0.5063,0 0.9167,-0.4104 0.9167,-0.9167 V 15.726 c 0.4825,-0.2106
        0.8778,-0.5809 1.1194,-1.0487 0.2416,-0.4678 0.3148,-1.0045 0.2072,-1.5199 -0.1075,-0.5153
        -0.3893,-0.978 -0.7978,-1.3101 -0.4086,-0.332 -0.919,-0.5133 -1.4455,-0.5133 -0.5265,0
        -1.0369,0.1813 -1.4454,0.5133 -0.4086,0.3321 -0.6903,0.7948 -0.7979,1.3101 -0.1076,0.5154
        -0.0344,1.0521 0.2072,1.5199 0.2416,0.4678 0.6369,0.8381 1.1195,1.0487 z"
        fill="url(#paint1_linear_3465_11147)"
        id="path4"
        style="fill:url(#paint1_linear_3465_11147)"
      />
    </g>
    <defs
      id="defs21"
    >
      <linearGradient
        id="paint0_linear_3465_11147"
        x1="28"
        y1="28"
        x2="2"
        y2="2"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-2,-2)"
      >
        <stop
          stop-color="#919191"
          id="stop8"
        />
        <stop
          offset="1"
          stop-color="#BEBEBE"
          id="stop10"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3465_11147"
        x1="9.9162798"
        y1="8.1249905"
        x2="30.5415"
        y2="29.586901"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="white"
          id="stop13"
        />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0.12"
          id="stop15"
        />
      </linearGradient>
      <clipPath
        id="clip0_3465_11147"
      >
        <rect
          width="22"
          height="22"
          fill="#ffffff"
          transform="translate(4,4)"
          id="rect18"
          x="0"
          y="0"
        />
      </clipPath>
    </defs>
  </svg>
</template>;
