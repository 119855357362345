import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import Home from '../views/Home';
import Terms from '../views/Terms';
import Search from '../views/Search';
import Login from '../views/Login';
import Fetch from '../views/Fetch';
import Files from '../views/Files';
import Upload from '../views/Upload';
import Success from '../views/Success';
import Contact from '../views/Contact';
import Error from '../views/Error';

const routePrefix = process.env.VUE_APP_ROUTE_PREFIX || '';

const routes = [
  {
    path: `${routePrefix}/:id`,
    name: 'Terms',
    component: Terms,
    meta: {
      index: 1,
      animation: true,
      auth: false,
    },
  },
  {
    path: `${routePrefix}/:id/search`,
    name: 'Search',
    component: Search,
    meta: {
      index: 2,
      animation: true,
      auth: false,
    },
    params: true,
  },
  {
    path: `${routePrefix}/:id/login`,
    name: 'Login',
    component: Login,
    meta: {
      index: 3,
      animation: true,
      auth: false,
    },
  },
  {
    path: `${routePrefix}/:id/fetch`,
    name: 'Fetch',
    component: Fetch,
    meta: {
      index: 4,
      animation: true,
      auth: true,
    },
  },
  {
    path: `${routePrefix}/:id/success`,
    name: 'Success',
    component: Success,
    meta: {
      index: 6,
      animation: true,
      auth: true,
    },
  },
  {
    path: `${routePrefix}/:id/contact`,
    name: 'Contact',
    component: Contact,
    meta: {
      index: 7,
      animation: true,
      auth: false,
    },
  },
  {
    path: '/usr/new/ptr/:clientName',
    name: 'NewUser',
    component: Home,
    beforeEnter(to, from, next) {
      store.dispatch('setClientName', to.params.clientName).then((res) => {
        next({ name: 'Home', params: { id: res.person_id } });
      });
    },
  },
  {
    path: `${routePrefix}/error`,
    name: 'Error',
    component: Error,
    meta: {
      index: 8,
      animation: true,
      auth: false,
    },
  },
  {
    path: `${routePrefix}/:id/files`,
    name: 'Files',
    component: Files,
    meta: {
      index: 9,
      animation: true,
      auth: true,
    },
  },
  {
    path: `${routePrefix}/:id/upload`,
    name: 'Upload',
    component: Upload,
    meta: {
      index: 4,
      animation: false,
      auth: true,
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: `${routePrefix}/error`,
  },
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const checkRoutes = (id, Qparams, REFRESHED, to, from, next) => {
  if (store.state.settings.isDemo) {
    return next();
  }

  if (id && id.length !== 36) {
    return next({ name: 'Error' });
  }

  if (REFRESHED && to.meta.index === 4) {
    return next();
  }

  if (REFRESHED && to.meta.index === 5) {
    return next();
  }

  if (to.meta.index > 2 && to.meta.index !== 8 && !store.state.authenticated && REFRESHED) {
    return next({ name: 'Terms', params: { id }, query: to.query });
  }

  if (from.name === 'Terms' && to.name === 'Search') {
    return next();
  }

  if (from.name === undefined && to.name === 'Search') {
    return next({ name: 'Terms', params: { id }, query: to.query });
  }

  return next();
};

router.beforeEach(async (to, from, next) => {
  const { id } = to.params;
  store.commit('SET_APP_UUID', id);
  document.title = `Rollee Connect - ${to.name}` || 'Rollee Connect';

  const REFRESHED = typeof from.meta.index === 'undefined';

  const urlParams = new URLSearchParams(window.location.search);
  const Qparams = Object.fromEntries(urlParams.entries());
  checkRoutes(id, Qparams, REFRESHED, to, from, next);
});

export default router;
