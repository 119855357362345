<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14.0001" r="12.1333" fill="url(#paint0_linear_5832_914)" />
    <g clip-path="url(#clip0_5832_914)">
      <g clip-path="url(#clip1_5832_914)">
        <path
          d="M13.9985 22.5525C9.27404 22.5525 5.44434 18.7228 5.44434 13.9983C5.44434 9.27387 9.27404 5.44417 13.9985 5.44417C18.723 5.44417 22.5527 9.27387 22.5527 13.9983C22.5527 18.7228 18.723 22.5525 13.9985 22.5525ZM13.1457 17.42L18.5888 11.9761C18.9227 11.6421 18.9227 11.1006 18.5887 10.7666C18.2547 10.4326 17.7131 10.4326 17.3791 10.7667L13.1457 15.0009L11.3305 13.1857C10.9964 12.8517 10.4549 12.8517 10.1209 13.1857C9.78689 13.5197 9.78689 14.0612 10.1209 14.3953L13.1457 17.42Z"
          fill="url(#paint1_linear_5832_914)" />
      </g>
    </g>
    <defs>
      <linearGradient id="paint0_linear_5832_914" x1="26.1334" y1="26.1334" x2="1.8667" y2="1.86676"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#919191" />
        <stop offset="1" stop-color="#BEBEBE" />
      </linearGradient>
      <linearGradient id="paint1_linear_5832_914" x1="9.25455" y1="7.58271" x2="28.5016"
        y2="27.6106" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0.12" />
      </linearGradient>
      <clipPath id="clip0_5832_914">
        <rect width="20.5333" height="20.5333" fill="white" transform="translate(3.7334 3.73334)" />
      </clipPath>
      <clipPath id="clip1_5832_914">
        <rect width="20.53" height="20.53" fill="white" transform="translate(3.7334 3.73334)" />
      </clipPath>
    </defs>
  </svg>

</template>
