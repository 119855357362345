<template>
  <div v-if="!showData" class="page page--fetch" :class="{ rtl: isRtl }">
    <Header />
    <div class="page__animation-wrapper">
      <div class="page__animation-icon"
      >
        <img
          class="page__animation-icon--main"
          :src="getAvatar(getSelectedSource)"
          alt="Logo" />
        <div class="page__animation-icon--secondary">
          <img
            src="../assets/file-icon.png"
            alt="Logo" />
        </div>
      </div>
      <div class="page__animation" id="animation" />
    </div>
    <div class="page__text-section">
      <p>Uploading & verifying</p>
      <span>Your data is encrypted and never made accessible
        to anyone without your permission
      </span>
    </div>
  </div>
  <div v-else class="page page--data">
    <Header />
    <div class="page--data-header">
      <DataSourceAvatar :source="getSelectedSource" :withShadow="true" />
    </div>
    <div class="modal__section">
      <div class="modal__section-title">
        <ExclamationOutlinedIcon />
        <p>
          Data extracted
        </p>
      </div>
      <div class="modal__section-content">
        <div class="data">
          <div class="data-item" v-if="displayData?.customer_name">
            <span>Customer name</span>
            <p>{{displayData.customer_name}}</p>
          </div>
          <div class="data-item" v-if="displayData?.customer_email">
            <span>Customer Email</span>
            <p>{{ displayData.customer_email }}</p>
          </div>
          <div class="data-item" v-if="displayData?.customer_mobile_number">
            <span>Mobile phone</span>
            <p>{{displayData.customer_mobile_number}}</p>
          </div>
          <div class="data-item" v-if="displayData?.statement_period">
            <span>Statement period</span>
            <p>{{displayData.statement_period}}</p>
          </div>
          <div class="data-item" v-if="displayData?.total_paid_in">
            <span>Total paid in</span>
            <p>{{displayData.total_paid_in}}</p>
          </div>
          <div class="data-item" v-if="displayData?.total_paid_out">
            <span>Total paid out</span>
            <p>{{displayData.total_paid_out}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="success__actions">
      <button
        class="button--primary"
        @click="onBackTo()"
        :style="`background: ${getConfig.cta_button_backcolor};
                   color: ${getConfig.cta_button_forecolor}`">
        <span v-if="getConfig.success_button_text">{{getConfig.success_button_text}}</span>
        <Translate v-else :translate="'goBack'" />
        {{customerName}}
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Localization from '@/mixins/localization';
import ExclamationOutlinedIcon from '@/components/icons/ExclamationOutlinedIcon';
import Header from '../components/Header';
import DataSourceAvatar from '../components/DataSourceAvatar';

export default {
  components: {
    Header,
    ExclamationOutlinedIcon,
    DataSourceAvatar,
  },
  name: 'Upload',
  mixins: [Localization],
  data() {
    return {
      bodymovinInstance: undefined,
      displayData: undefined,
      showData: false,
    };
  },
  computed: {
    ...mapGetters([
      'getSelectedSource',
      'getFileUpload',
      'getConfig',
    ]),
    customerName() {
      return this.getConfig.partner_title;
    },
  },
  beforeMount() {
    if (this.getSelectedSource === undefined) {
      this.$router.push({ name: 'Terms', query: this.$route.query });
    }
  },
  mounted() {
    this.setupMovinAnimation();
    this.$store.dispatch('sendFile', this.getFileUpload).then((res) => {
      if (!res) {
        setTimeout(() => {
          this.$store.commit('SET_ERROR', {
            name: 'Error',
            message: 'Something went wrong, please try again  ',
          });
          this.$router.push({ name: 'Files', query: this.$route.query });
        }, 1000);
        return;
      }
      this.showData = true;
      this.displayData = res;
    }).catch(() => {
      this.$store.commit('SET_ERROR', {
        name: 'Error',
        message: 'Something went wrong, please try again  ',
      });
      this.$router.push({ name: 'Files', query: this.$route.query });
    });
  },
  unmounted() {
    if (this.showData) {
      setTimeout(() => {
        this.clear();
      }, 400);
    }
  },
  methods: {
    async setupMovinAnimation() {
      // eslint-disable-next-line
      const bodymovinInstance = await bodymovin.loadAnimation({
        container: document.getElementById('animation'),
        renderer: 'svg',
        // eslint-disable-next-line import/extensions,global-require,import/no-unresolved
        animationData: require('@/../public/animations/file-upload-spinner.json'),
        name: 'fetchAnimation',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
          progressiveLoad: true,
          runExpressions: true,
        },
      });
      this.bodymovinInstance = bodymovinInstance;
    },
    onBackTo() {
      this.displayData = undefined;
      this.$router.push({ name: 'Terms', query: this.$route.query });
    },
    clear() {
      this.displayData = undefined;
      this.$store.commit('SET_SELECTED_SOURCE', undefined);
    },
  },
};
</script>
<style lang="scss" scoped>
.page{
  &--fetch{
    display: grid;
    grid-template-rows: 25px 250px auto;
    align-content: flex-start;
  }

  &--data{
    display: grid;
    grid-template-rows: 25px 100px auto auto;
    align-content: flex-start;
    &-header{
      margin-top: auto;
    }
  }
  &__animation{
    position: absolute;
    height: 126px;
    width: 126px;
    &-wrapper{
      height: 126px;
      width: 126px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;
    }

    &-icon{
      width: 48px;
      height: 48px;
      border-radius: 999px;
      z-index: 10;
      position: relative;
      &--main{
        width: 100%;
        height: 100%;
        border-radius: 999px;
      }
      &--secondary{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -25px;
        right: -25px;
        width: 40px;
        height: 40px;
        border-radius: 999px;
        background-color: $white;
        border: 1px solid $grey-outline;
      }
    }
  }

  &__progress{
    &-wrapper{
      padding: 0;
      list-style: none;
    }
  }

  &__text-section{
    text-align: center;
    & p{
      color: $black;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 6px;
    }
    & span{
      color: $dark-grey;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
  }
}
.data{
  width: 100%;
  margin-top: auto;
  margin-bottom: 20px;
  display: grid;

  margin-top: 20px;
  &-item{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 5px;
    & span{
      font-weight: 500;
    }
  }

}
.modal{
  &__section{
    margin: 20px 0;
    width: calc(100% - 40px);
  }
}
</style>
