<template>
	<div class="modal__wrapper">
		<div class="modal modal--date">
			<div class="modal__content" :class="{desktop: !isMobile}">
        <div class="date-picker"></div>
			</div>
      <div class="modal__actions" :class="{desktop: !isMobile}">
        <button
          class="button--secondary"
          @click="closeModal"
          @keydown.enter="closeModal">
          <Translate :translate="'close'" />
        </button>
        <button
          class="button--primary"
          @click="confirm"
          @keydown.enter="login">
          <Translate :translate="'confirm'" />
        </button>
      </div>
		</div>
	</div>
</template>
<script>
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localeEn from 'air-datepicker/locale/en'

import General from "@/mixins/general";
export default {
	name: 'DatePickerModal',
  mixins: [General],
  data() {
    return {
      state: {
        date: undefined,
        isoDate: undefined,
      },
    };
  },
  mounted() {
    this.datePicker = new AirDatepicker('.date-picker', {
      inline: true,
      locale: localeEn,
      isMobile: true,
      maxDate: new Date(),
      onSelect: (event) => {
        if(!event.date) return;
        const date = new Date(event.date);
        date.setDate(date.getDate() + 1);
        this.state.isoDate = date.toISOString();
        // Format date to DD-MM-YYYY
        this.state.date = `${date.getDate() - 1}-${date.getMonth() + 1}-${date.getFullYear()}`;
      },
    });
  },
	methods: {
		closeModal() {
			this.$store.dispatch('setDateModal', false);
		},
    confirm() {
      this.$emit('confirm', this.state);
      this.$store.dispatch('setDateModal', false);
    },
	},
};
</script>
<style lang="scss" scoped>
.modal{
  &__content{
    height: 236px;
    &.desktop{
      padding: 20px 80px 20px 80px;
    }
  }
  &__actions{
    display: grid;
    border-top: 2px solid #EDF2F8;
    margin: 16px 16px 0 16px;
    padding-top: 16px;
    grid-template-columns: 1fr 2fr;
    column-gap: 12px;
    &.desktop{
      margin: 20px 80px 0 80px;
    }
  }

}
.date-picker{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
