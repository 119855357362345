<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.52274 11.3305L13.8825 6.96996L14.3537 7.44118L9.16913 12.6265L6.34043
    9.79783L6.81166 9.3266L8.8156 11.3305L9.16919 11.6841L9.52274 11.3305ZM1.16666
    10.0003C1.16666 14.879 5.12135 18.8337 9.99999 18.8337C14.8786 18.8337 18.8333 14.879
    18.8333 10.0003C18.8333 5.12168 14.8786 1.16699 9.99999 1.16699C5.12135 1.16699 1.16666
    5.12168 1.16666 10.0003Z"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>;
