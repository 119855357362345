import { mapGetters } from 'vuex';

const mixin = {
  name: 'Localization',
  computed: {
    ...mapGetters(['getConfig']),
    textDirection() {
      switch (this.getConfig.lang) {
        // Add different languages
        case 'ar':
          return 'rtl';
        default:
          return 'ltr';
      }
    },
    isRtl() {
      switch (this.getConfig.lang) {
        // Add different languages
        case 'ar':
          return true;
        default:
          return false;
      }
    },
  },
};

export default mixin;
