<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.67032 3.6295L1.78057 11.5192L0.484406 10.2231L8.37324
    2.33333H1.42032V0.5H11.5037V10.5833H9.67032V3.6295Z"
      fill="#A8AFBC" />
  </svg>
</template>

<script>
export default {
  name: 'RightCornerArrowIcon',
};
</script>
