<template>
  <div class="page modal-approval" :class="{ 'page--remove': removeModal }">
    <Header :hasClose="true" />
    <div class="page__content">
      <div class="modal__logo">
        <img
          v-if="getAvatar(getSelectedSource)"
          :src="getAvatar(getSelectedSource)"
          alt="Logo" />
        <div class="modal__animation__wrapper">
          <div class="modal__animation" id="approvalAnimation" />
        </div>
      </div>
      <p class="modal__title">
        <Translate :translate="'approvalSignIn'" />
        <span class="modal__title-highlight">
          {{ dataSourceName }}
        </span>
      </p>
      <div class="modal__section">
        <div class="modal__section-title">
          <ExclamationOutlinedIcon />
          <p>
            <Translate :translate="'approvalTitle'" />
          </p>
        </div>
        <div class="modal__section-content">
          <p class="modal__text">
            <Translate :translate="approvalMessagePerDatasource" />
          </p>
        </div>
      </div>

      <div class="page__actions" v-if="getFetchStatus['otp-status'] !== 'APPROVAL'">
        <button
          :disabled="disableButton"
          @click="submit"
          class="button button--primary button--block"
        >
          <Translate :translate="'approvalButton'" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header';
import Analytics from '@/api/analytics';
import ExclamationOutlinedIcon from '@/components/icons/ExclamationOutlinedIcon';

export default {
  name: 'ApprovalModal',
  components: {
    Header,
    ExclamationOutlinedIcon,
  },
  data() {
    return {
      disableButton: true,
      removeModal: false,
      bodymovinInstance: undefined,
    };
  },
  mounted() {
    this.setupMovinAnimation();
    setTimeout(() => {
      this.disableButton = false;
    }, 3000);

    Analytics.dispatch({
      action: 'getSessionDuration',
      fromScreen: this.$route.name,
      toScreen: 'ApprovalOTP',
    });
  },
  computed: {
    ...mapGetters(['getSelectedSource', 'getFetchStatus']),
    approvalMessagePerDatasource() {
      switch (true) {
        case this.getSelectedSource.url === 'agencia-tributaria':
          return 'otpApprovalMessageAgenciaTributaria';
        default:
          return 'approvalText';
      }
    },
    dataSourceName() {
      if (this.getSelectedSource.child_datasources) {
        if (this.getSelectedSource.next_datasource_id) {
          return this.getSelectedSource.child_datasources[0].title;
        }
        return this.getSelectedSource.child_datasources[1].title;
      }
      return this.getSelectedSource.name;
    },
  },
  methods: {
    submit() {
      this.removeModal = true;
      setTimeout(() => {
        this.$store.dispatch('sendApprovalMFA', this.code).then(() => {
          this.$store.commit('SET_APPROVAL_MODAL', false);
        });
      }, 250);
    },
    async setupMovinAnimation() {
      // eslint-disable-next-line
      const bodymovinInstance = await bodymovin.loadAnimation({
        container: document.getElementById('approvalAnimation'),
        renderer: 'svg',
        // eslint-disable-next-line import/extensions,global-require,import/no-unresolved
        animationData: require('@/../public/animations/approval-otp-spinner.json'),
        name: 'fetchAnimation',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
          progressiveLoad: true,
          runExpressions: true,
        },
      });
      this.bodymovinInstance = bodymovinInstance;
    },
  },
};
</script>

<style scoped lang="scss">
  .page{
    &__actions{
      margin-top: 28px;
    }
  }
</style>
