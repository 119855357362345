<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5258_4012)">
      <path
        d="M14.1666 4.99996H18.3333V6.66663H16.6666V17.5C16.6666 17.721 16.5788
  17.9329 16.4225 18.0892C16.2663 18.2455 16.0543 18.3333 15.8333
  18.3333H4.16663C3.94561 18.3333 3.73365 18.2455 3.57737 18.0892C3.42109
  17.9329 3.33329 17.721 3.33329 17.5V6.66663H1.66663V4.99996H5.83329V2.49996C5.83329
  2.27895 5.92109 2.06698 6.07737 1.9107C6.23365 1.75442 6.44561 1.66663 6.66663
  1.66663H13.3333C13.5543 1.66663 13.7663 1.75442 13.9225 1.9107C14.0788 2.06698
  14.1666 2.27895 14.1666 2.49996V4.99996ZM15 6.66663H4.99996V16.6666H15V6.66663ZM7.49996
  3.33329V4.99996H12.5V3.33329H7.49996Z"
        fill="#7A808B"
      />
    </g>
    <defs>
      <clipPath id="clip0_5258_4012">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>;
