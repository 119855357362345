<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="add-fill" clip-path="url(#clip0_5258_3902)">
      <g id="Group">
        <path
          id="Vector"
          d="M12.8333 12.8334V7.00004C12.8333 6.35571 13.3556 5.83337 13.9999
  5.83337C14.6443 5.83337 15.1666 6.35571 15.1666 7.00004V12.8334H20.9999C21.6443 12.8334
  22.1666 13.3557 22.1666 14C22.1666 14.6444 21.6443 15.1667 20.9999
  15.1667H15.1666V21C15.1666 21.6444 14.6443 22.1667 13.9999 22.1667C13.3556
  22.1667 12.8333 21.6444 12.8333 21V15.1667H6.99992C6.35559 15.1667 5.83325
  14.6444 5.83325 14C5.83325 13.3557 6.35559 12.8334 6.99992 12.8334H12.8333Z"
          fill="#7A808B"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5258_3902">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>;
