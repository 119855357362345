import { defaultTo } from 'lodash';

const component = {
  name: 'Translate',
  props: [
    'translate',
  ],
  computed: {
    translation() {
      const t = defaultTo(this.$store.getters.translations[this.translate], `${this.translate}`);
      return t;
    },
  },
  template: ' <span>{{ translation }}</span> ',
};

export default component;
