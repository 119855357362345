<template>
  <div class="page page--code" :class="{ 'page--remove': removeModal }">
    <Header
      :title="'Rollee Ride'"
      :hasBack="true"
      :hasClose="true"
      @goBack="onBackToLogin" />
    <div class="page__content">
      <div class="login__source horizontal">
        <div class="login__source-icon">
          <img
            v-if="getAvatar(getSelectedSource)"
            ref="sourceLogo"
            :src="getAvatar(getSelectedSource)" />
          <div v-else class="page__list-default" />
        </div>
        <div :dir="textDirection" class="login__source-title">
          <h2>
            <Translate :translate="'logInTo'" />
            {{ dataSourceName }}
          </h2>
        </div>
        <div class="login__source-info">
          <p :dir="textDirection">
            {{ otpMessageType }}
          </p>
          <span v-if="displayLoginWith">
            {{ displayLoginWith }}
          </span>
          <span v-else>
            <Translate :translate="'otpDefault'" />
          </span>
        </div>
      </div>
      <ErrorNotification v-if="getError" />
      <div class="input__wrapper">
        <textarea
          v-model="code"
          data-gramm="false"
          data-hj-allow
          class="data-hj-allow"
          @keydown.enter.prevent
          autofocus
          rows="0" />
      </div>

      <div class="code__tips" v-if="codeTip">
        {{ codeTip }}
      </div>
      <div class="page__actions">
        <button
          @click="submit"
          :disabled="isDisabled"
          class="button button--primary button--block"
          :class="{ 'button--loading': getFetchUUID }"
        >
          <Translate :translate="'send'" />
        </button>
        <button
          v-if="getSelectedSource['resend-otp']"
          :disabled="resendDisabled"
          @click="onResend"
          class="button button--secondary button--block"
          :class="{ 'button--loading': getFetchUUID }"
        >
          <Translate :translate="'resendCode'" />
        </button>
        <div v-if="resendDisabled" class="page__actions-info">
          <span><Translate :translate="'resendWait'" /></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header';
import Localization from '@/mixins/localization';
import analytics from '@/api/analytics';
import ErrorNotification from '@/components/ErrorNotification';

export default {
  name: 'CodeModal',
  components: {
    ErrorNotification,
    Header,
  },
  data() {
    return {
      code: undefined,
      removeModal: false,
      resendDisabled: false,
    };
  },
  mixins: [Localization],
  mounted() {
    analytics.dispatch({
      action: 'getSessionDuration',
      fromScreen: this.$route.name,
      toScreen: this.getFetchStatus['otp-status'],
    });
  },
  computed: {
    ...mapGetters([
      'getFetchUUID',
      'translations',
      'getFetchStatus',
      'getError',
      'getSelectedSource',
      'getLoginCredentials',
      'getCountries',
    ]),
    otpMessageType() {
      switch (true) {
        case this.getFetchStatus['otp-status'] === 'AUTHENTICATOR_APP_OTP':
          if (this.getSelectedSource?.url === 'agencia-tributaria') {
            return this.translations.otpAuthenticatorSeguridad;
          }
          return this.translations.otpAuthenticator;
        case this.getFetchStatus['otp-status'] === 'SMS_OTP':
          return this.translations.otpSms;
        default:
          return this.translations.otpCode;
      }
    },
    codeTip() {
      switch (this.getFetchStatus['otp-status']) {
        case 'MAIL_OTP':
          return this.translations.codeEmailTip;
        default:
          return undefined;
      }
    },
    isDisabled() {
      // eslint-disable-next-line
      return this.code || this.code?.length ? false : true;
    },
    displayLoginWith() {
      switch (this.getLoginCredentials?.type) {
        case 'phone':
          return this.getDialCode();
        case 'email':
          if (this.getFetchStatus['otp-status'] === 'MAIL_OTP') {
            return this.getLoginCredentials?.username;
          }
          return ' ';
        default:
          return ' ';
      }
    },
    dataSourceName() {
      if (this.getSelectedSource.child_datasources) {
        if (this.getSelectedSource.next_datasource_id) {
          return this.getSelectedSource.child_datasources[0].title;
        }
        return this.getSelectedSource.child_datasources[1].title;
      }
      return this.getSelectedSource.name;
    },
  },
  methods: {
    submit() {
      this.removeModal = true;
      this.$store.dispatch('sendConfirmationCode', this.code).then(() => {
        setTimeout(() => {
          this.closeModal();
          this.$emit('startLoop', true);
        }, 500);
      }).catch(() => {
        this.$store.commit('SET_ERROR', {
          name: 'Error',
          description: 'Error while sending confirmation code.',
        });
        this.$emit('startLoop', false);
      });
    },
    onResend() {
      // Disable Resend input for 15sec and display 'resendWait' message when disabled
      this.resendDisabled = true;
      setTimeout(() => {
        this.resendDisabled = false;
      }, 15000);
      // Check property if resend is allowed for that datasource
      if (this.getSelectedSource['resend-otp']) {
        this.$emit('resend');
      }
    },
    closeModal() {
      this.$store.commit('SET_ERROR', null);
      this.$store.commit('SET_CODE_MODAL', false);
    },
    onBackToLogin() {
      this.closeModal();
      this.$router.push({ name: 'Login' });
    },
    getDialCode() {
      const [code, phone] = this.getLoginCredentials.username.split('-');
      const dialCode = this.getCountries.find((country) => country.code === code);
      return `${dialCode.dial_code} ${phone}`;
    },
  },
};

</script>
<style lang="scss" scoped>
.code{
  &__source {
    margin: 24px 0 20px 0;
  }
  &__tips {
    color: $dark-grey;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.5px;
    text-align: start;
    margin-bottom: 12px;
    transform: translateY(-8px);
  }
}
.page {
  &__actions {
    button:first-of-type {
      margin-bottom: 20px;
    }
    &-info {
      margin-top: 6px;
      span {
        color: $dark-grey;
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 17.5px
      }
    }
  }
}
.input__wrapper {
  margin-top: 22px;
  min-height: 32px;
  textarea {
    letter-spacing: 4px;
  }
}
</style>
