<template>
  <div class="modal page page--modal modal__terms">
    <Header
      :hasBack="true"
      :hasLogo="false"
      :hasTitle="true"
      :title="termsTitle"
      @goBack="goBack" />
    <div class="page__content">
      <div v-html="termsOfUse" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Header from './Header';

export default {
  name: 'TermsOfUseModal',
  components: {
    Header,
  },
  data() {
    return {
      termsOfUse: undefined,
      termsTitle: undefined,
    };
  },
  mounted() {
    this.getTerms();
  },
  methods: {
    ...mapActions(['getTermsOfUse', 'getPartnersTermsOfUse', 'getPrivacyPolice']),
    goBack() {
      this.$store.commit('SET_LEGAL_INFO_MODAL', false);
      this.$store.commit('SET_TYPE_OF_TERMS', undefined);
    },
    async getTerms() {
      switch (this.getTermsOfUsePartner) {
        case 'moove':
        case 'rollee':
          this.termsOfUse = await this.getPartnersTermsOfUse(this.getTermsOfUsePartner);
          this.termsTitle = `${this.clientTitle} Terms of Use`;
          break;
        case 'privacy':
          this.termsOfUse = await this.getPrivacyPolice(this.getLocale);
          this.termsTitle = 'modalPrivacyPolicyTitle';
          break;
        default:
          this.termsOfUse = await this.getTermsOfUse(this.getLocale);
          this.termsTitle = 'modalTermsOfUseTitle';
      }
    },
  },
  computed: {
    ...mapGetters(['getLocale', 'getTermsOfUsePartner', 'getClientName']),
    clientTitle() {
      return this.$store.state.config.partner_title;
    },
  },

};
</script>

<style scoped lang="scss">
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 99;
    padding: 20px 0;
    grid-template-rows: $header-height auto;
    & header{
      margin: 0 28px;
      width: calc(100% - 56px);
    }

    &__footer {
      display: grid;
      grid-template-rows: 1fr 1fr;
      gap: 12px;
    }
  }

  .page{
    &__content{
      text-align: left;
      overflow-y: auto;
      width: calc(100% - 56px);
      padding: 0 28px;
      margin-top: 40px;
    }
  }
</style>
