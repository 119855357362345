<template>
  <div class="page page--home">
    <Header :title="'Rollee Ride'" :hasClose="true" />
    <div class="page__content">
      <h1 class="page__title">
        <Translate v-if="!getConfig.intro_screen_title" :translate="'homePageTitle'" />
        <span v-else>{{getConfig.intro_screen_title}}</span>
      </h1>
      <button
        class="page__button"
        :style="`background: ${getConfig.cta_button_backcolor};
                 color: ${getConfig.cta_button_forecolor}`"
        @click="gotoTerms()"
      >
        <Translate v-if="!getConfig.cta_button_text" :translate="'homeGetStarted'" />
        <span v-else>{{getConfig.cta_button_text}}</span>
      </button>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
  },
  methods: {
    gotoTerms() {
      this.$router.push({ name: 'Terms', query: this.$route.query });
    },
  },
  computed: {
    ...mapGetters(['getConfig']),
  },
  mounted() {
    this.$store.commit('SET_SEARCH_INPUT_FOCUSED', false);
  },
};
</script>
