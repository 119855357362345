<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4852_3584)">
      <path
        d="M9.33335 5.33329V26.6666H22.6667V5.33329H9.33335ZM8.00002 2.66663H24C24.3536
      2.66663 24.6928 2.8071 24.9428 3.05715C25.1929 3.3072 25.3334 3.64634 25.3334
      3.99996V28C25.3334 28.3536 25.1929 28.6927 24.9428 28.9428C24.6928 29.1928 24.3536
      29.3333 24 29.3333H8.00002C7.6464 29.3333 7.30726 29.1928 7.05721 28.9428C6.80716
      28.6927 6.66669 28.3536 6.66669 28V3.99996C6.66669 3.64634 6.80716 3.3072 7.05721
      3.05715C7.30726 2.8071 7.6464 2.66663 8.00002 2.66663ZM16 22.6666C16.3536 22.6666
      16.6928 22.8071 16.9428 23.0571C17.1929 23.3072 17.3334 23.6463 17.3334 24C17.3334
      24.3536 17.1929 24.6927 16.9428 24.9428C16.6928 25.1928 16.3536 25.3333 16
      25.3333C15.6464 25.3333 15.3073 25.1928 15.0572 24.9428C14.8072 24.6927 14.6667
      24.3536 14.6667 24C14.6667 23.6463 14.8072 23.3072 15.0572 23.0571C15.3073 22.8071
      15.6464 22.6666 16 22.6666Z"
        fill="#A8AFBC"
      />
      <circle cx="23.5" cy="4.5" r="3.5" fill="#A8AFBC" stroke="white" stroke-width="2" />
    </g>
    <defs>
      <clipPath id="clip0_4852_3584">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>;
