import http from './http';
// eslint-disable-next-line import/no-cycle
import state from '../store/state';
import helpers from '@/helpers/helpers';

const getPath = () => {
  if (state.settings.isDemo) {
    return '';
  }
  return `${process.env.VUE_APP_API_CONNECT_PATH}/`;
};

const API = {
  base: process.env.VUE_APP_API_BASE_URL || 'connect-fe/0.3/',
  getSources: '/sources',
  fetchNew: '/',
  fetchSet: '/fetch/',
  fetchStatus: '/fetch/',
  newClient: (clientName) => `ptr/${clientName}/usr/new`,
  getConfig: '/partner',
  unknownDs: '/notify-unknown-ds/',
  getSessionInfo: '/session_properties',
  consentAccepted: '/consent',
  fileBasePath: process.env.VUE_APP_FILE_BASE_URL,
};

const getUUID = () => {
  const path = window.location.pathname.split('/');
  if (path[3]?.length < 36) {
    return false;
  }
  return path[3];
};

const getSources = async (config) => {
  const UUID = getUUID();
  const DOMAIN_PATH = getPath(config);
  const response = await http.get(`${DOMAIN_PATH}${API.base}${API.getSources}`, {
    headers: {
      'Content-Type': 'application/json',
      'User-Token': UUID,
    },
  });
  const result = response.data;
  return result;
};

const getConfiguration = async (config) => {
  try {
    const UUID = getUUID();
    const DOMAIN_PATH = getPath(config);
    // const customization_id = config.customization_id ? config.customization_id : localStorage?.getItem('customization_id') ;
    const response = await http.get(`${DOMAIN_PATH}${API.base}${API.getConfig}`, {
      headers: {
        'Content-Type': 'application/json',
        'User-Token': UUID,
      },
      params: {
        customization_id: config.customization_id,
      },
    });
    const result = response.data;
    return result;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      // Handle 500 error here
      console.error('Wrong customization ID');
    } else {
      throw error;
    }
   return error;
  }
};

const getSessionInfo = async (config) => {
  const UUID = getUUID();
  const DOMAIN_PATH = getPath(config);
  const response = await http.get(`${DOMAIN_PATH}${API.base}${API.getSessionInfo}`, {
    headers: {
      'Content-Type': 'application/json',
      'User-Token': UUID,
    },
  });
  const result = response.data;
  return result;
};

const connectToSource = async (config, source, credentials) => {
  const AUTH_TYPE = '';
  const UUID = getUUID();
  const PAYLOAD = {
    auth_country: credentials.country,
    auth_user: credentials.username,
    auth_password: helpers.sanitizePassword(credentials.password),
    auth_method: credentials.type,
    auth_date_of_birth: credentials.date,
    datasource: source.ID,
  };
  const DOMAIN_PATH = getPath(config);
  let response;
  if (config.product === 'connect') {
    response = await http.post(`${DOMAIN_PATH}${API.base}${API.fetchNew}`, PAYLOAD, {
      headers: {
        'Content-Type': 'application/json',
        'User-Token': UUID,
      },
    });
  } else {
    response = await http.get(`${DOMAIN_PATH}${API.base}${API.fetchNew}?user=${config.uuid}&auth-user=${PAYLOAD.username}&auth-type=${AUTH_TYPE}&source=${source.url}&no-poll=true`, { PAYLOAD });
  }
  const result = response.data;
  return result;
};

const loopForStatus = async (config, fetchUUID) => {
  const DOMAIN_PATH = getPath(config);
  const UUID = getUUID();
  const response = await http.get(
    `${DOMAIN_PATH}${API.base}${API.fetchStatus}${fetchUUID}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'User-Token': UUID,
      },
    },
  );
  const result = response.data;

  return result;
};

const sendConfirmationCode = async (config, code, fetchUUID) => {
  const DOMAIN_PATH = getPath(config);
  const UUID = getUUID();
  const response = await http.put(`${DOMAIN_PATH}${API.base}${API.fetchSet}${fetchUUID}`, {
    code,
  }, {
    headers: {
      'Content-Type': 'application/json',
      'User-Token': UUID,
    },
  });
  const result = response.data;

  return result;
};

const sendApproval = async (config, code, fetchUUID) => {
  const DOMAIN_PATH = getPath(config);
  const UUID = getUUID();
  const response = await http.put(`${DOMAIN_PATH}${API.base}${API.fetchSet}${fetchUUID}`, {
    code,
  }, {
    headers: {
      'Content-Type': 'application/json',
      'User-Token': UUID,
    },
  });
  const result = response.data;

  return result;
};

const sendEmail = async (config, payload) => {
  const UUID = getUUID();
  const DOMAIN_PATH = getPath(config);
  const response = await http.post(`${DOMAIN_PATH}${API.base}${API.unknownDs}${encodeURI(payload)}`, null, {
    headers: {
      'Content-Type': 'application/json',
      'User-Token': UUID,
    },
  });
  const result = response.data;
  return result;
};

const sendConsent = async (config, payload) => {
  const UUID = getUUID();
  const DOMAIN_PATH = getPath(config);
  const response = await http.post(`${DOMAIN_PATH}${API.base}${API.consentAccepted}`, payload, {
    headers: {
      'Content-Type': 'application/json',
      'User-Token': UUID,
    },
  });
  const result = response.data;
  return result;
};
const analytics = async (payload) => {
  const UUID = getUUID();
  const DOMAIN_PATH = process.env.VUE_APP_API_CONNECT_PATH;
  const ANALYTICS_PATH = process.env.VUE_APP_ANALYTICS;
  const response = await http.post(`${DOMAIN_PATH}${ANALYTICS_PATH}/${payload.type}`, payload.data, {
    headers: {
      'Content-Type': 'application/json',
      'User-Token': UUID,
    },
  });
  const result = response.data;
  return result;
};

const setClientName = async (config, clientname) => {
  const DOMAIN_PATH = getPath(config);
  const response = await http.post(`${DOMAIN_PATH}${API.base}${API.newClient(clientname)}`);
  const result = response.data;
  return result;
};

const getTermsOfUse = async (config, local) => {
  const response = await http.get(`./terms/terms-of-use-${local}.html`);
  const result = response.data;
  return result;
};

const getPrivacyPolicy = async (config, local) => {
  const response = await http.get(`./terms/privacy-policy-${local}.html`);
  const result = response.data;
  return result;
};

const getTaxInfo = async (config, platform) => {
  const response = await http.get(`./html/tax-info-${platform}.html`);
  const result = response.data;
  return result;
};

const getDataProtectPolicy = async (config, local) => {
  const response = await http.get(`./html/protect-data-${local}.html`);
  const result = response.data;
  return result;
}

const getPartnersTermsOfUse = async (config, partnerName) => {
  const response = await http.get(`./terms/partners/terms-of-use-${partnerName}.html`);
  const result = response.data;
  return result;
};

const sendResendOtp = async (config, fetchUUID) => {
  const DOMAIN_PATH = getPath(config);
  const UUID = getUUID();
  const response = await http.post(`${DOMAIN_PATH}${API.base}${API.fetchSet}${fetchUUID}/resend_otp`, null, {
    headers: {
      'Content-Type': 'application/json',
      'User-Token': UUID,
    },
  });
  const result = response.data;

  return result;
};

const sendFile = async (config, file) => {
  const DOMAIN_PATH = getPath(config);
  const UUID = getUUID();
  try {
    const response = await http.post(`${DOMAIN_PATH}${API.base}/documents`, file, {
      headers: {
        'User-Token': UUID,
        'Content-Type': 'multipart/form-data',
      },
    });
    const result = response.data;

    return result;
  } catch (error) {
    return Error(error);
  }
};
export default {
  getSources,
  connectToSource,
  sendConfirmationCode,
  sendEmail,
  sendApproval,
  sendConsent,
  loopForStatus,
  analytics,
  setClientName,
  getConfiguration,
  getSessionInfo,
  getTermsOfUse,
  getPrivacyPolicy,
  getPartnersTermsOfUse,
  sendResendOtp,
  sendFile,
  getDataProtectPolicy,
  getTaxInfo
};
