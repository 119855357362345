<template>
  <svg width="13" height="13" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99999 5.58599L11.95 0.635986L13.364 2.04999L8.41399 6.99999L13.364 11.95L11.95
    13.364L6.99999 8.41399L2.04999 13.364L0.635986 11.95L5.58599 6.99999L0.635986 2.04999L2.04999
    0.635986L6.99999 5.58599Z"
      fill="#A8AFBC" />
  </svg>
</template>
<style scoped lang="scss">
.icon {
  cursor: pointer;
  &--close {
    padding: 27px 14px;
  }
}
</style>
<script setup>
</script>
